import React from "react";
import useTranslations from "../../components/useTranslations";
import Footer from "../../components/Footer/Footer";
import CompanyLeftNav from "../../components/TwoColumnLeftNav/CompanyLeftNav";

const Company = () => {
	const { companyProfile } = useTranslations();
	const InfoJa = [
		{
			title: "名称",
			body: "株式会社佐藤計量器製作所",
			itemProp: "name",
		},
		{
			title: "法人番号",
			body: "3 0100 0101 7270",
			itemProp: "hasCredential",
		},
		{
			title: "会長",
			body: "佐藤 健治",
			itemProp: "member",
		},
		{
			title: "社長",
			body: "佐藤 陽一",
			itemProp: "member",
		},
		{
			title: "創立",
			body: "昭和23年4月",
			itemProp: "foundingDate",
		},
		{
			title: "設立",
			body: "昭和33年4月",
			itemProp: "",
		},
		{
			title: "資本金",
			body: "82,000,000円",
			itemProp: "ownershipFundingInfo",
		},
	];

	const InfoEn = [
		{
			title: "Chairman ",
			body: "Mr. Kenji Sato",
			itemProp: "member",
		},
		{
			title: "President ",
			body: "Mr. Yoichi Sato",
			itemProp: "member",
		},
		{
			title: "Establishment",
			body:
				"Founded in April, 1948 as a manufacturer of glass thermometers. Changed the organization in April, 1958",
			itemProp: "foundingDate",
		},
		{
			title: "Capital",
			body: "J. Yen82,000,000",
			itemProp: "ownershipFundingInfo",
		},
		{
			title: "Employees",
			body: "140",
			itemProp: "numberOfEmployees",
		},
		{
			title: "Activities",
			body: "Manufacture, Distribution",
			itemProp: "potentialAction",
		},
		{
			title: "Products ",
			body:
				"Meteorological instruments, industrial thermometers, and other measuring instruments",
			itemProp: "description",
		},
	];

	const ContentJa = () => {
		return (
			<div itemScope itemType="http://schema.org/Organization">
				{InfoJa.map((InfoItem, index) => {
					return (
						<li className="flex" key={index}>
							<p className={"info-title"}>{InfoItem.title}</p>
							<p className={"info-body"} itemProp={InfoItem.itemProp}>
								{InfoItem.body}
							</p>
						</li>
					);
				})}
				<li className="flex">
					<p className={"info-title"}>取引銀行</p>
					<p className={"info-body"}>
						三井住友銀行日本橋支店 <br className="sp" />
						三菱ＵＦＪ銀行日本橋支社
					</p>
				</li>
				<li className="flex">
					<p className={"info-title"}>加入業界団体</p>
					<p className={"info-body"}>
						日本科学機器団体連合会 <br className="sp" />
						日本計量機器工業連合会 <br className="sp" />
						東京科学機器協会　　他
					</p>
				</li>
			</div>
		);
	};

	const ContentEn = () => {
		return (
			<div itemScope itemType="http://schema.org/Organization">
				{InfoEn.map((InfoItem, index) => {
					return (
						<li className="flex" key={index}>
							<p className={"info-title"}>{InfoItem.title}</p>
							<p className={"info-body"} itemProp={InfoItem.itemProp}>
								{InfoItem.body}
							</p>
						</li>
					);
				})}
				<li className="flex">
					<p className={"info-title"}>Our Banks</p>
					<p className={"info-body"}>
						MUFG Bank Ltd. Muromachi Branch <br />
						Sumitomo-Mitsui Banking Corporation Nihonbashi Branch
					</p>
				</li>
			</div>
		);
	};

	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<article className="company-info two-column-left-nav">
				<CompanyLeftNav />
				<section className="main-content">
					<div className="img-box company-hero flex align-center">
						<h1>{companyProfile}</h1>
					</div>
					<h2 className={"large bold"}>{companyProfile}</h2>
					<div className="content-container gray">
						<div className="inner">
							<ul className={"company-info-flex"}>
								{pathName.indexOf("/en") !== -1 ? <ContentEn /> : <ContentJa />}
							</ul>
						</div>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Company;
